<template>
	<div class="px-3 py-4 text-left md:px-4 xl:px-1.5 md:py-5">
		<WwReadMore @is-expanded="setExpanded">
			<WwHtmlContent
				class="text-left"
				:markup="introText"
				:expanded="expanded"
				use-line-clamp
			/>
		</WwReadMore>
	</div>
</template>

<script async>
import WwHtmlContent from '@/components/UI/WwHtmlContent.vue'
import WwReadMore from '@/components/UI/WwReadMore.vue'

export default {
	components: {
		WwHtmlContent,
		WwReadMore
	},
	props: {
		introText: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			expanded: false
		}
	},
	watch: {
		introText: {
			handler: function () {
				this.readMore = this.introText && this.introText.length < 1500
			},
			immediate: true
		}
	},
	methods: {
		setExpanded(expanded) {
			this.expanded = expanded
		}
	}
}
</script>
